export function setupNetworkSlider() {
  var $slider = $('.partner--slider');
  $slider.slick({
    dots: false,
    autoplay: true,
    infinite: true,
    draggable: true,
    swipeToSlide: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    responsive: [
    {
        breakpoint: 1199,
        settings: {
          slidesToShow: 4
        }
      },
    {
        breakpoint: 992,
        settings: {
          slidesToShow: 4
        }
      },
    {
        breakpoint: 767,
        settings: {
          slidesToShow: 3
        }
      },
    {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
        }
      },
    {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          arrows: false
        }
      }
  ]
  });
}