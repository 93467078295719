import IMG_FEATURED_PIN from './img/img_popular_spaces.svg?url';
import IMG_NORMAL_PIN from './img/img_pinpoint_map.svg?url';
import IMG_RATING_STAR from './img/img_star.svg?url';

const NON_NUMERIC = /[^0-9-.]/g;

window.Page = window.Page || {};
let Page = window;
Page.baseUrl = hydrateJSON.baseUrl;
Page.countryCities = hydrateJSON.cities,
Page.cloudFrontUrl = hydrateJSON.cloudFrontUrl;
Page.cityId = hydrateJSON.cityId;
Page.countryId = hydrateJSON.countryId;
Page.viewMode = hydrateJSON.viewMode;
Page.networkCode = hydrateJSON.networkCode;
Page.currentPageNum = hydrateJSON.currentPageNum;
Page.pricingMax = hydrateJSON.priceMax;
Page.filters = [];
Page.filters.amenityIds = [];
Page.filters.maxMeetingRoomCapacity = 0;
Page.filters.pricingStart = null;
Page.filters.pricingEnd = null;

Page.filters.resourceTypes = 'all';
Page.filters.durationMetricTypes = 'all';

export function loadSearchResults(pageNum) {
    Page.currentPageNum = pageNum;

    let postParams = {
        "city_id": Page.cityId,
        "country_id": Page.countryId,
        "view_mode": Page.viewMode,
        "network_code": Page.networkCode,
        "page_num": Page.currentPageNum,
        "filter_amenity_ids": Page.filters.amenityIds,
        "filter_meeting_room_capacity": Page.filters.maxMeetingRoomCapacity,
        "filter_price_start": Page.filters.pricingStart,
        "filter_price_end": Page.filters.pricingEnd,
        "filter_resource_types": Page.filters.resourceTypes,
        "filter_duration_metric_types": Page.filters.durationMetricTypes,
        "page_size": Page.pageSize,
    };

    fetchSearchResults(postParams)
}

function fetchSearchResults(postParams){
    $.ajax({
        type: 'POST',
        url: Page.baseUrl + "ajax/spaces",
        data: postParams,
        success: function (data) {
            var cityLink = $(".network-search-all-spaces-link").clone();
            $("#search_results").html(data.renderedResults);
            if (cityLink.length > 0) {
                $("#search_results").append(cityLink);
            }
            if (typeof open_network_city !== 'undefined') {
                $('.search-space-main-outer').on('click', open_network_city);
            }
            if (hydrateJSON.viewMode == 'map' || hydrateJSON.viewMode == 'city') {
              setSpaceListeners();
              window.currentMapSpaces = data.mapSpaces;
              window.currentCenterCoordinates = data.mapSpaces;
            }
            $([document.documentElement, document.body]).animate({
                scrollTop: $("#search_results").offset().top - 100
            }, 0);
            $("#num_spaces").html(data.numSpaces);
            $("#desk_count").html(data.deskCount);
            $("#private_office_count").html(data.privateOfficeCount);
            $("#meeting_room_count").html(data.meetingRoomCount);
            $("#virtual_office_count").html(data.virtualOfficeCount);
        },
        error: function (result) {
            $("#search_results").html("<p>There was an issue fetching your search results</p>");
        },
        fail: (function (status) {
            $("#search_results").html("<p>There was an issue fetching your search results</p>");
        }),
        beforeSend: function (d) {
            //$('#reviews').html("<center><strong style='color:red'>Please Wait...<br><img height='25' width='120' src='<?php echo base_url();?>img/ajax-loader.gif' /></strong></center>");
        }
    });
}

function renderMapMarkers(points) {
  const map = L.map('map', {
      minZoom: 2,
      maxZoom: 18
  }).setView([points[0].city_lat, points[0].city_lng], 12);
  $('#map').css("height", '100%');
  $('.leaflet-bottom.leaflet-right').css('display', 'none');

  // check if we can replace the link below to   'https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png'
  L.tileLayer('https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png', {
      maxZoom: 19,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
  }).addTo(map);

  map.zoomControl.setPosition('topright');

  let popup = '<div class="map-infobox-hover-inner" style="min-width: 175px; box-shadow: none; position: relative;"><a target="_blank" href="#" tabindex="0" style="position:absolute;top:0;left:0;bottom:0;right:0;z-index:1;"></a><div style="padding: 0;" class="col-xs-12 pad-none map_pic"><img style="max-height: 125px;max-width:175px" class="main-img" src=""><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin:auto;background:#fff;display:block;shape-rendering:auto" width="100px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid"><circle cx="50" cy="50" r="0" fill="none" stroke="#00d192" stroke-width="2"><animate attributeName="r" repeatCount="indefinite" dur="1s" values="0;40" keyTimes="0;1" keySplines="0 0.2 0.8 1" calcMode="spline" begin="0s"></animate><animate attributeName="opacity" repeatCount="indefinite" dur="1s" values="1;0" keyTimes="0;1" keySplines="0.2 0 0.8 1" calcMode="spline" begin="0s"></animate></circle><circle cx="50" cy="50" r="0" fill="none" stroke="#00d192" stroke-width="2"><animate attributeName="r" repeatCount="indefinite" dur="1s" values="0;40" keyTimes="0;1" keySplines="0 0.2 0.8 1" calcMode="spline" begin="-0.5s"></animate><animate attributeName="opacity" repeatCount="indefinite" dur="1s" values="1;0" keyTimes="0;1" keySplines="0.2 0 0.8 1" calcMode="spline" begin="-0.5s"></animate></circle></svg></div><span class="col-12 pad-none map-infobox-hover-space-name" style="font-weight: 600; font-size: 12px; line-height: 29px; color: #00927C;">&nbsp;</span><h3 style="font-size:13px; font-weight: bold; margin-bottom: 7px; width: 100%;"></h3><p class="map-infobox-hover-space-address" style="margin:-5px 0 5px;font-size: 11px;color: #6d7372;font-weight: 600;"></p><div class="col-12 pad-none map-infobox-hover-reviews" style="font-weight: bold; font-size: 10px; display: flex;"></div></div>';

  const markers = L.geoJSON(null, {
      pointToLayer: createClusterIcon,
      onEachFeature: function onEachFeature(feature, layer) {
          if (feature.properties && feature.properties.id) {
              layer.bindPopup(popup);
          }
      }
  }).addTo(map);

//     // check if we can remove this
//   if (sysVars.user_agent !== 'CWTestAgent91929394' && sysVars.environment !== 'development' && typeof L.tileLayer.provider === 'function') {
//     L.tileLayer.provider('Stadia.AlidadeSmooth').addTo(map);
//   }

  function getPointInfo(id) {
      $.ajax({
          url: `${Page.baseUrl}getSpaceInfo`,
          dataType: "json",
          type: "POST",
          data: {
              c_Id: id
          },
          success: function({
              sName,
              sUrl,
              imgUrl,
              sPrice,
              sRate,
              sAddress
          }) {
              $('.map-infobox-hover-inner svg').css('display', 'none');
              $('.map-infobox-hover-inner h3').text(sName);
              $('.map-infobox-hover-inner a').attr('href', sUrl);
              $('.map-infobox-hover-inner .main-img').attr('src', imgUrl);
              $('.map-infobox-hover-space-address').html(sAddress);
              // check if price and reviews > 0
              if (!!+sPrice.replace(/[^0-9]/g, '')) {
                $('.map-infobox-hover-space-name').text(sPrice);
              } else {
                $('.map-infobox-hover-space-name').remove()
                $('.map_pic').css('margin-bottom', '5px');
              }
              if (!!+sRate.replace(/[^0-9]/g, '')) {
                $('.map-infobox-hover-reviews').html(sRate);
              }

              const popupHeight = $('.map-infobox-hover-inner').height();
              $('.leaflet-popup-content-wrapper').css('height', popupHeight + 30);
          }
      });
  }

  // Update the displayed clusters after user pan / zoom.
  map.on('moveend', update);
  // Check if zoom changes so as not to click popup twice
  let zoomCache;

  function update() {
      if (!ready) return;
      const bounds = map.getBounds();
      const bbox = [bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth()];
      const zoom = map.getZoom();
      const clusters = index.getClusters(bbox, zoom);
      if (zoomCache !== zoom) {
          markers.clearLayers();
      }
      markers.addData(clusters);
      zoomCache = zoom;
      //upon zoom/pan update if auto animation is triggered show marker popup
      if (autoAnim) {
          enableMapInteraction();
          getPointInfo(pendingSpaceId);
          map.eachLayer((el)=>{
              if (typeof(el.feature) !== 'undefined'
                  && typeof(el.feature.properties) !== 'undefined'
                  && typeof(el.feature.properties.id) !== 'undefined') {
                  if (el.feature.properties.id == pendingSpaceId) {
                      el.openPopup();
                  }
              }
          });
      }
  }

  // Zoom to expand the cluster clicked by user.
  markers.on('click', function(e) {
      const clusterId = e.layer.feature.properties.cluster_id;
      const pointId = e.layer.feature.properties.id;
      const center = e.latlng;
      let expansionZoom;
      if (clusterId) {
          expansionZoom = index.getClusterExpansionZoom(clusterId);
          if (expansionZoom == map.getZoom()) {
            expansionZoom += 1;
          }
          map.flyTo(center, expansionZoom);
      } else if (pointId) {
          getPointInfo(pointId);
      }
  });

  let autoAnim = false;
  let pendingSpaceId = false;

  function disableMapInteraction() {
      autoAnim = true;
      map.dragging.disable();
      map.touchZoom.disable();
      map.doubleClickZoom.disable();
      map.scrollWheelZoom.disable();
      map.boxZoom.disable();
      map.keyboard.disable();
      if (map.tap) map.tap.disable();
      document.getElementById('map').style.cursor='default';
  }

  function enableMapInteraction() {
      autoAnim = false;
      map.dragging.enable();
      map.touchZoom.enable();
      map.doubleClickZoom.enable();
      map.scrollWheelZoom.enable();
      map.boxZoom.enable();
      map.keyboard.enable();
      if (map.tap) map.tap.enable();
      document.getElementById('map').style.cursor='grab';
  }

  /**
   * Zoom and pan to marker and show it's popup by provided space id
   * @param space_id
   */
  function showMapMarkerBySpaceId(space_id) {
      if (space_id == pendingSpaceId) {
          return;
      }
      let spaceData = fetchSpaceDataById(space_id);
      let center = {
          lat: spaceData.location.lat,
          lng: spaceData.location.lng
      }
      map.flyTo(center, 14, {duration: 1});
      disableMapInteraction();
      pendingSpaceId = space_id;
  }
  window.showMapMarkerBySpaceId = showMapMarkerBySpaceId;

  // Retrieve Points data.
  const generateMarkers = (points) => {
      const result = {
          features: []
      };
      for (let i = 0; i < points.length; i++) {
          result.features.push({
              type: "Feature",
              properties: {
                  id: points[i].id,
              },
              geometry: {
                  type: "Point",
                  coordinates: [points[i].location.lng, points[i].location.lat]
              },
          })
      }
      return result;
  };

  const geojson = generateMarkers(points);
  var index;
  var ready = false;
  index = supercluster({
      radius: 60,
      extent: 256,
      maxZoom: 13
  }).load(geojson.features); // Expects an array of Features.
  ready = true;
  update();


  function createCustomIcon() {
    const pathToIcon = '<svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 10.4348C20 16.1978 13 19.8261 10 24C7 19.8261 0 16.1978 0 10.4348C0 4.67181 4.47715 0 10 0C15.5228 0 20 4.67181 20 10.4348Z" fill="url(#paint0_linear_10015_96965)"/><defs><linearGradient id="paint0_linear_10015_96965" x1="10" y1="0" x2="10" y2="24" gradientUnits="userSpaceOnUse"><stop stop-color="#01927C"/><stop offset="1" stop-color="#017866"/></linearGradient></defs></svg>';
    return L.divIcon({
        html: pathToIcon,
        className: 'custom-marker',
        iconSize: L.point(20, 20)
    });
  }

  function createCustomClusterIcon(count) {
    const pathToCluster = '<svg width="34" height="41" viewBox="0 0 34 41" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M34 17.9913C34 27.5962 22.6667 33.3536 17 40.6C11.3333 33.3536 0 27.5962 0 17.9913C0 8.38633 7.61116 0.599976 17 0.599976C26.3888 0.599976 34 8.38633 34 17.9913Z" fill="url(#paint0_linear_10015_96976)"/><defs><linearGradient id="paint0_linear_10015_96976" x1="17" y1="0.599976" x2="17" y2="40.6" gradientUnits="userSpaceOnUse"><stop stop-color="#01927C"/><stop offset="1" stop-color="#017765"/></linearGradient></defs></svg>';
    return L.divIcon({
        html: `<span>${count}</span>${pathToCluster}`,
        className: 'custom-cluster-marker',
        iconSize: L.point(40, 40)
    });
  }

  function createClusterIcon(feature, latlng) {
    const { cluster, point_count_abbreviated } = feature.properties;

    if (cluster) {
        return L.marker(latlng, { icon: createCustomClusterIcon(point_count_abbreviated) });
    } else {
        return L.marker(latlng, { icon: createCustomIcon() });
    }
  }

  setSpaceListeners();
}

function setSpaceListeners() {
  const elements = document.querySelectorAll('[id^="search-space-"]');
  elements.forEach((el)=>{
     el.addEventListener('mouseenter', (e)=>{
         let space_id = e.currentTarget.id.replace('search-space-', '');
         showMapMarkerBySpaceId(space_id);
     })
  });
}

function fetchSpaceDataById(space_id) {
  let filtered = window.currentMapSpaces.filter((el, index) => {
      if (el.id == space_id) {
          return true;
      }
      return false;
  });
  return filtered[0];
}


var priceStart = hydrateJSON.priceStart;
var priceEnd = hydrateJSON.priceEnd;
$("#d1 input").on('change', function () {
    let start = Number(this.value.replace(NON_NUMERIC, ''));
    start = Math.max(Math.min(start, Page.filters.pricingEnd), 0);
    Page.filters.pricingStart = start;
    this.value = start;
    $("#monthly-price-slider").slider('values', [start, Page.filters.pricingEnd]);
});
$("#d2 input").on('change', function () {
    let end = Number(this.value.replace(NON_NUMERIC, ''));
    end = Math.max(Math.min(end, priceEnd), 0, Page.filters.pricingStart);
    Page.filters.pricingEnd = end;
    this.value = end;
    $("#monthly-price-slider").slider('values', [Page.filters.pricingStart, end]);
});
async function setupPriceSlider() {
    Page.filters.pricingStart = priceStart;
    Page.filters.pricingEnd = priceEnd;
    await import('../../_shared/vendor/jquery-ui-slider');

    $("#monthly-price-slider").slider({
        min: priceStart,
        max: priceEnd,
        range: true,
        values: [priceStart, priceEnd],
        slide: function (event, ui) {
            const start = ui.values[0];
            const end = ui.values[1];
            $("#d1 input").val(start);
            $("#d2 input").val(end);
            Page.filters.pricingStart = start;
            Page.filters.pricingEnd = end;
        }
    });

    $("#d1 input").val(0);
    $("#d2 input").val(Page.pricingMax);
}


window.addEventListener('scroll', () => {
    requestAnimationFrame(() => {
        const mapsScript = document.createElement('script');
        mapsScript.async = true;
        mapsScript.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCrDWVmZFIo_jp5aJUvJYDU_wMXK7PMA6Q&callback=initMap&libraries=&v=weekly';
        document.head.appendChild(mapsScript);
    });
}, {passive: true, once: true});

window.initMap = () => {
  if (hydrateJSON.viewMode === 'map' || hydrateJSON.viewMode === 'city') {
      window.currentMapSpaces = hydrateJSON.mapSpaces;
      window.currentCenterCoordinates = hydrateJSON.centerCoordinates;
      renderMapMarkers(hydrateJSON.mapSpaces, hydrateJSON.centerCoordinates);
    }
}

$(function () {

    $('.home-input-radio.durations').on('click', function (e) {

        Page.filters.durationMetricTypes = [];
        $.each($('input[name="durations"]:checked'), function () {
            Page.filters.durationMetricTypes.push($(this).val());
        });

        if (Page.filters.durationMetricTypes == false) {
            Page.filters.durationMetricTypes = null;
        }

        loadSearchResults(Page.currentPageNum);

    });

    // FILTER SAVE BUTTONS
    $('.home-input-radio.resource-types').on('click', function (e) {
        Page.filters.resourceTypes = [];
        $.each($('input[name="resource_types"]:checked'), function () {
            Page.filters.resourceTypes.push($(this).val());
        });

        loadSearchResults(Page.currentPageNum);
    });


    $('#save_price_range_button').on('click', function (e) {
        e.preventDefault();
        loadSearchResults(Page.currentPageNum);
    });

    $('#save_meeting_capacity_button').on('click', function (e) {
        e.preventDefault();
        loadSearchResults(Page.currentPageNum);
    });

    $('body').on('click', 'a.search-page-link', function (e) {
        e.preventDefault();
        let PageNum = $(this).data("search-page-num");
        loadSearchResults(PageNum);

    });

    //SEARCH PAGE TOGGLES
    $(".map-view-dropdown").on('click', function () {
        $(".map-dropdown-toggle").toggle();
    });
})


var init_div = false;
var init_div2 = false;
$(".view-filters").on('click', function (e) {

    if (init_div == false) {
        $(".space-mapview-filter-outer").show();
        init_div = true;
    } else {
        $(".space-mapview-filter-outer").hide();
        init_div = false;
    }
    init_div2 = false;
    e.stopPropagation();
    e.preventDefault();
});

$('.price-range-save-button').on('click', function () {
    $(".space-mapview-filter-outer").hide();
    init_div = false;
});

$('.price-range-save-button').on('click', function () {
    $(".coworker-price-range-dropdown02").hide();
    init_div2 = false;
});

$(".price-range").on('click', function (e) {

    //coworker-price-range-dropdown02
    if (init_div2 == false) {
        $(".coworker-price-range-dropdown02").show();
        init_div2 = true;
    } else {
        $(".coworker-price-range-dropdown02").hide();
        init_div2 = false;
    }
    //price-range-save-button

    init_div = false;
    e.stopPropagation();
    e.preventDefault();
});

$(".view-filters-lists-outer li a").on('click', function () {
    $(this).toggleClass("filter-selected");
});



$('#clear_price_range_button').on('click', function (e) {
    e.preventDefault();
    setupPriceSlider();
});

$(document).on('mouseup', function (e) {
    var container = $(".space-mapview-filter-outer");

    // if the target of the click isn't the container nor a descendant of the container
    if (!container.is(e.target) && container.has(e.target).length === 0) {
        container.hide();
    }
    var container2 = $(".map-dropdown-toggle");

    // if the target of the click isn't the container nor a descendant of the container
    if (!container2.is(e.target) && container2.has(e.target).length === 0) {
        container2.hide();
    }

    var container3 = $(".coworker-price-range-dropdown02");

    // if the target of the click isn't the container nor a descendant of the container
    if (!container3.is(e.target) && container3.has(e.target).length === 0) {
        container3.hide();
    }
});


requestAnimationFrame(async () => {
    // Only load if the page gets interaction
    await new Promise((resolve) => {
        window.addEventListener('scroll', resolve, { once: true, passive: true });
        document.addEventListener('click', resolve, { once: true, passive: true });
        document.addEventListener('keydown', resolve, { once: true, passive: true });
    });
    setupPriceSlider();
})
